import { isString } from "library/guards";
import {
  isEmrType,
  isMemberBirthCountry,
  isMemberGender,
  isMemberNationalityCountry,
} from "library/guards/enum";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import moment from "moment";
import translations, { InvoiceContentTypes } from "res/translations";
import { PickKeys } from "ts-essentials";
import { $BasicAddress, $MemberName } from "types/index";
import {
  BusinessType,
  HealthPlanAmendmentType,
  HealthPlanType,
  InPersonConsultType,
  InvoiceType,
  Maybe,
  MemberType,
  PaymentMethodsPaymentMethodType,
  PharmacyOrderType,
  ServicesType,
  UserType,
} from "types/schema";
import { filterNil } from "./array";
import {
  getFormattedDate,
  getFormattedDateAndTime,
  getFormattedDateAndTimeNonUtc,
  getFormattedTime,
} from "./date";
import { prettyJoin } from "./string";

export const getNumber = (value?: Maybe<number | string>) => {
  return isNil(value) ? "" : value;
};

export const getNumberAsString = (value?: Maybe<number>) => {
  return isNil(value) ? "" : `${value}`;
};

export const getBoolean = (value?: Maybe<boolean>) => {
  return isNil(value) ? false : value;
};

export const getString = <T extends string>(value?: Maybe<T>): T | "" => {
  return isNil(value) ? "" : value;
};

export const getArray = <T>(value?: Maybe<T>[] | T[] | null): Array<T> => {
  return isNil(value) ? [] : filterNil(value);
};

export const getMemberGender = (value?: Maybe<string>) => {
  return isMemberGender(value) ? value : "";
};

export const getMemberGenderText = (value?: Maybe<string>) => {
  return isMemberGender(value)
    ? translations.gender[value]
    : translations.globals.undefined;
};

export const getMemberBirthCountry = (value?: Maybe<string>) => {
  return isMemberBirthCountry(value) ? value : "";
};

export const getMemberNationalityCountry = (value?: Maybe<string>) => {
  return isMemberNationalityCountry(value) ? value : "";
};

export const getDate = (date?: Maybe<string>) => {
  return isNil(date) ? "" : getFormattedDate(date);
};

export const getTime = (date?: Maybe<string>) => {
  return isNil(date) ? "" : getFormattedTime(date);
};

export const getDateAndTime = (date?: Maybe<string>) => {
  return isNil(date) ? "" : getFormattedDateAndTime(date);
};

export const getDateAndTimeNonUtc = (date?: Maybe<string>) => {
  return isNil(date) ? "" : getFormattedDateAndTimeNonUtc(date);
};

export const getJSONString = (value?: Maybe<string>) => {
  if (isNil(value) || isEmpty(value)) {
    return "";
  }
  const parsedValue = JSON.parse(value);
  return JSON.stringify(parsedValue, null, 2);
};

export const getUserName = (user?: Maybe<Partial<UserType>>) => {
  if (isNil(user)) return "";
  return prettyJoin([user.firstName, user.lastName, user.secondLastName]);
};

export const getMemberName = (member?: Maybe<$MemberName>) => {
  if (!member) return "";
  return prettyJoin([
    member?.firstName,
    member?.firstLastName,
    member?.secondLastName,
  ]);
};

export const getPaymentMethodType = (paymentMethodType?: string) => {
  if (!paymentMethodType) return "";

  return translations.paymentMethodsPaymentMethods[
    paymentMethodType as PaymentMethodsPaymentMethodType
  ];
};

export const getProviderPaymentType = (deferInvoice?: boolean) => {
  if (isNil(deferInvoice)) return undefined;
  return deferInvoice ? "Pago por periodo" : "Pago por servicio";
};

export const getAddress = (address: Partial<$BasicAddress>) => {
  return prettyJoin(
    [
      address.address1?.trim(),
      address.address2?.trim(),
      address.neighborhood?.trim(),
      address.municipality?.trim(),
      address.city?.trim(),
      address.stateName?.trim(),
      address.zipcode?.trim() && `CP ${address.zipcode.trim()}`,
    ],
    { separator: ", " },
  );
};

export const getIsOwner = (
  user?: Maybe<Partial<UserType>>,
  member?: Maybe<Partial<MemberType>>,
) => {
  if (isNil(user) || isNil(user.id) || isNil(member) || isNil(member.id)) {
    return false;
  }
  return user.id === member.id;
};

export const getEmrType = (value?: Maybe<string>) => {
  return isEmrType(value) ? value : "";
};

export const getEmrTypeText = (value?: Maybe<string>) => {
  return isEmrType(value)
    ? translations.emrType[value]
    : translations.globals.undefined;
};

export const centsToPesos = (value?: Maybe<string | number>) => {
  if (isNil(value)) return "";
  const _value = isString(value) ? Number(value) : value;
  return `${_value / 100}`;
};

export const pesosToCents = (value?: Maybe<string>) => {
  if (isNil(value)) return "";
  const _value = isString(value) ? Number(value) : value;
  return `${_value * 100}`;
};

export const getContentType = (
  items?: InvoiceType["items"],
): InvoiceContentTypes | null => {
  const filtered = filterNil(items ?? []);
  return filtered[0]?.contentType?.model as InvoiceContentTypes;
};

export const getAmendmentCategory = (
  items?: InvoiceType["items"],
): string | "" => {
  const filtered = filterNil(items ?? []);
  const t = filtered[0]?.contentObject as HealthPlanAmendmentType;
  if (isNil(t)) return "";
  return t.amendmentCategory;
};

export const getServiceCategory = (
  items?: InvoiceType["items"],
): string | "" => {
  const filtered = filterNil(items ?? []);
  const t = filtered[0]?.contentObject as ServicesType;
  if (isNil(t.serviceType)) return "";
  return t.serviceType.name || "";
};

export const getInvoiceUser = (entry: InvoiceType) => {
  const contentType = getContentType(entry.items);
  if (isEmpty(contentType)) return undefined;

  const filtered = filterNil(entry.items ?? []);

  if (filtered.length <= 0) return undefined;

  let user,
    contentObject = undefined;

  switch (contentType) {
    case InvoiceContentTypes.Ha:
      contentObject = filtered[0]?.contentObject as HealthPlanAmendmentType;
      user = contentObject.healthPlan?.owner;
      break;
    case InvoiceContentTypes.Ip:
      contentObject = filtered[0]?.contentObject as InPersonConsultType;
      user = contentObject.healthPlan?.owner;
      break;
    case InvoiceContentTypes.Hp:
      contentObject = filtered[0]?.contentObject as HealthPlanType;
      user = contentObject?.owner;
      break;
    case InvoiceContentTypes.Po:
      contentObject = filtered[0]?.contentObject as PharmacyOrderType;
      user = contentObject.user;
      break;
  }

  if (isNil(user)) return undefined;

  return user;
};

/** Removes null for Ant components */
export const rmNull = <T>(value?: Maybe<T>): T extends null ? undefined : T => {
  // Disabling rule because of the dynamic return type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (isNil(value) ? undefined : value) as any;
};

export const getMoment = (dateString?: string | null) => {
  return dateString ? moment(dateString) : undefined;
};

export const getDiffInYearsAndMonths = (
  dateString: string,
  isSofiaDate = false,
) => {
  if (!moment(dateString).isValid()) return "Fecha inválida";
  const years = moment().diff(dateString, "years", false);
  const months = moment().diff(dateString, "months", false) % 12;
  const yearText = years === 1 ? "año" : "años";
  const monthsText = months === 1 ? "mes" : "meses";

  const sofiaString = isSofiaDate ? " en Sofía" : "";
  if (years === 0 && months === 0)
    return `Menos de un mes de antigüedad${sofiaString}`;
  if (years === 0 && months > 0)
    return `${months} ${monthsText} de antigüedad${sofiaString}`;
  if (years > 0 && months === 0)
    return `${years} ${yearText} de antigüedad${sofiaString}`;
  return `${years} ${yearText} y ${months} ${monthsText} de antigüedad${sofiaString}`;
};

export const calculateIMC = (weight: number, height: number) => {
  return Number(weight) / Math.pow(Number(height) / 100, 2);
};

/**
 * @deprecated Use `name` from `BusinessType` instead
 */
export const getBusinessName = (
  business: Pick<BusinessType, "commonName" | "legalName">,
) => {
  return business.commonName ? business.commonName : business.legalName;
};

export const getSelectBinaryValues = <T>(
  data: T | undefined,
  key: PickKeys<T, boolean | null | undefined>,
) => {
  const value = data?.[key];
  if (typeof value === "boolean") {
    return value ? 1 : 0;
  }
};

export const getSelectBooleanValues = <
  T,
  ValueType extends 0 | 1 | undefined,
  KeyType extends PickKeys<T, ValueType>,
>(
  data: T,
  key: KeyType,
): T[KeyType] extends number ? boolean : boolean | undefined => {
  const value = data[key];
  // Disabling rule because of the dynamic return type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (typeof value === "number" ? value === 1 : undefined) as any;
};

export const getMexicoStateName = (
  object: any,
  value: string | null | undefined,
) => {
  return Object.keys(object)
    .find(key => object[key] === value)
    ?.replace(/([A-Z])/g, " $1")
    .trim();
};
